import { motion } from "framer-motion";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import Btn from "../uikit/Btn";

import icon1 from "@/assets/home/icon1.webp";
import icon2 from "@/assets/home/icon2.webp";
import icon3 from "@/assets/home/icon3.webp";
import icon4 from "@/assets/home/icon4.webp";
import icon5 from "@/assets/home/icon5.webp";
import icon6 from "@/assets/home/icon6.svg";
import icon7 from "@/assets/home/icon7.webp";
import icon8 from "@/assets/home/icon8.svg";
import icon9 from "@/assets/home/icon9.webp";
import { useIsMobile } from "../hooks/useMobile";
import Link from "next/link";

const HeroSetion = () => {
  return (
    <>
      {/* <Image
        layout="responsive"
        objectFit="cover"
        width={1000}
        height={1000}
        src={img.src}
        property
        className="w-screen h-screen -z-10 -top-[0vh] opacity-30  absolute"
        alt=""
      /> */}
      <section className="w-full relative px-8  md:py-12 grid grid-cols-1 bg-opacity-0 md:grid-cols-2 items-center gap-8 max-w-7xl mx-auto">
        {/* <div className="w-[70vw] h-[100vh] -z-20  absolute -top-[40vh] -left-[40vw]  bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-red-200 via-white to-white"></div>
        <div className="w-[70vw] h-[100vh] -z-20  absolute -bottom-[40vh] -right-[40vw]  bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-200 via-white to-white"></div> */}
        <div>
          <span className="block mb-4 text-xs md:text-sm text-[#EC1C1C] font-medium">
            Autodesk Solutions
          </span>
          <h1 className="text-4xl md:text-6xl font-semibold">
            Budget-Friendly Autodesk Solutions Customized For{" "}
            <span className="font-bold text-red-700 ">You</span>
          </h1>
          <p className="text-base md:text-lg text-slate-700 my-4 md:my-6">
            Transform your projects affordably with our cutting-edge software.
            Unlock the full potential of Autodesk services without breaking the
            bank. Experience a blend of precision, innovation, and affordability
            for unparalleled design success.
          </p>
          <Btn text={"Discover Our Offers"} link={"/softwares"} />
        </div>
        <ShuffleGrid />
      </section>
    </>
  );
};

const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

const squareData = [
  {
    id: 1,
    src: icon1,
    link: "/product/autocad-all-apps-1-year-subscription-pc"
  },
  {
    id: 2,
    src: icon2,
    link: "/product/revit-1-year-license-key-2022-2025-pc"
  },
  {
    id: 3,
    src: icon3,
    link: "/product/autocad-all-apps-1-year-subscription-pc"
  },
  {
    id: 4,
    src: icon4,
    link: "/product/autocad-civil-3d-1-year-license-key-2022-2025-pc"
  },
  {
    id: 5,
    src: icon5,
    link: "/product/autocad-1-year-license-keys-2018-2025"
  },
  {
    id: 6,
    src: icon6,
    link: "/product/autocad-all-apps-1-year-subscription-pc"
  },
  {
    id: 7,
    src: icon7,
    link: "/product/autocad-all-apps-1-year-subscription-pc"
  },
  {
    id: 8,
    src: icon8,
    link: "/product/autocad-all-apps-1-year-subscription-pc"
  },
  {
    id: 9,
    src: icon9,
    link: "/product/autocad-all-apps-1-year-subscription-pc"
  },
];

const generateSquares = () => {
  return shuffle(squareData).map((sq) => (
    <motion.div
      key={sq.id}
      layout
      transition={{ duration: 1.5, type: "spring" }}
      className="w-full h-[148px] "
      style={
        {
          // backgroundImage: `url(${sq.src})`,
          // backgroundSize: "cover",
        }
      }
    >
      <Link href={sq.link}>
        <Image
          src={sq.src}
          alt="img"
          width={300}
          height={300}
          quality={100}
          priority
          className="w-full h-full object-contain"
        />
      </Link>
    </motion.div>
  ));
};

const ShuffleGrid = () => {
  const timeoutRef = useRef(null);
  const [squares, setSquares] = useState(generateSquares());
  const mobile = useIsMobile()

  useEffect(() => {
    !mobile && shuffleSquares();

    return () => clearTimeout(timeoutRef.current);
  }, []);

  const shuffleSquares = () => {
    setSquares(generateSquares());

    timeoutRef.current = setTimeout(shuffleSquares, 3000);
  };

  return (
    <div className="grid grid-cols-3 grid-rows-3 h-[450px] gap-1 justify-center items-center">
      {squares.map((sq) => sq)}
    </div>
  );
};

export default HeroSetion;
